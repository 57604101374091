

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Breadcrumb } from '@/typings';
import ArrowIcon from 'vue-material-design-icons/ChevronRight.vue';

@Component({ components: { ArrowIcon } })
export default class Breadcrumbs extends Vue {
  @Prop({ type: Array, required: true }) breadcrumbs!: Breadcrumb[];

  getAriaLabel(title: string): string {
    return `${title} (link do podstrony otworzy się w tym samym oknie)`;
  }

  shouldItemBeAriaHidden(index: number): string {
    return this.breadcrumbs.length - 1 === index ? 'true' : 'false';
  }

  shouldItemBeFocusable(index: number): number {
    return this.breadcrumbs.length - 1 === index ? -1 : 0;
  }
}
